<script>
    import appConfig from "./../../app.config.json";
    import Footer from "../components/landing/Footer";
    import i18n from "../i18n";
    import { authComputed } from "@/state/helpers";
    import NavBar from "@/components/landing/NavBar";

    export default {
        page: {
            title: "Starter",
            meta: [{
                name: "description",
                content: appConfig.description
            }],
        },
        data() {
            return {
                title: "Damask",
                lan: i18n.locale,
                items: [{
                        text: "Pages",
                        href: "/",
                    },
                    {
                        text: "Damask",
                        active: true,
                    },
                ],
            };
        },
        components: {
          NavBar,
          Footer,
        },
        methods: {
        },
        computed: {
          ...authComputed,
        },
        mounted() {
            document.querySelector('.currentyear').innerHTML = new Date().getFullYear() + " © Damask";
        },
    };
</script>
<style>
ol {
  list-style-type: none;
  counter-reset: item;
  margin: 0;
  padding: 0;
}

ol > li {
  display: table;
  counter-increment: item;
  margin-bottom: 0.6em;
}

ol > li:before {
  content: counters(item, ".") ". ";
  display: table-cell;
  padding-right: 0.6em;
}

li ol > li {
  margin: 0;
}

li ol > li:before {
  content: counters(item, ".") " ";
}
</style>
<template>
    <div class="layout-wrapper landing">
      <NavBar></NavBar>


      <section class="section pb-0 hero-section bg-white" id="doc-privacy-policy">
        <div class="bg-white bg-overlay-pattern"></div>
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-lg-8 col-sm-10">
              <div class="text-left mt-lg-5 pt-5">
                <h1 class="display-6 fw-semibold mb-3 lh-base">Terms and Conditions</h1>
                <ol>
                  <li>
                    <h2 id="terms-and-definitions">Terms and definitions</h2>
                    <p>
                      For the purposes of this Regulation, the following terms are used with the following meanings:
                    </p>
                    <ul>
                      <li><b>Platform</b> - Internet site Damask Trading located on the Internet at: https://damask- trading.com/.</li>
                      <li><b>User</b> - A private person who is involved in the functioning of the Platform or using the results of its functioning.</li>
                      <li><b>Personal data</b> - any information relating to the Platform User.</li>
                      <li><b>Processing of personal data</b> - actions (operations) with personal data, including collection, systematization, accumulation, storage, clarification (updating, changing), use, distribution (including transfer), depersonalization, blocking, destruction of personal data.</li>
                      <li><b>Registration (signup)</b> - the process when you provide your data to the Platform in order to gain access to the full functionality of the system, whether it be additional features or access to resources that unauthorized Users cannot view or download.</li>
                      <li><b>Authentication</b> - the process of verifying the User's data that were provided during Registration in order to open access to the closed sections of the Platform and to the additional functionality of the Platform.</li>
                      <li><b>Distribution of personal data</b> - actions, that result in the disclosure of Personal Data to unidentified persons.</li>
                      <li><b>Depersonalization of personal data</b> - actions, due to which it is impossible to determine the ownership of personal data by a specific subject of personal data.</li>
                      <li><b>Account</b> - a set of data about the User that was provided during Registration and which are stored in the Platform database, in order to identify him and provide access to closed resources of the Platform.</li>
                      <li><b>Operator</b> - a legal entity (SIA "Damask"), the state registration of which was carried out in accordance with the established procedure on the territory of the Republic of Latvia, which owns the Platform and processes Personal Data, and also determines the purposes and content of Personal Data processing</li>
                      <li><b>Regulations</b> - the current internal document regulating the work of the Operator in the processing of Personal Data.</li>
                      <li><b>Trading robot</b> - is an automatic trading system that, based on a certain algorithm, independently makes transactions for buying and selling cryptocurrencies.</li>
                      <li><b>API</b> - a special protocol for the interaction of computer programs that allows you to use the functions of one application inside another.</li>
                    </ul>
                  </li>
                  <li>
                    <h2>General provisions</h2>
                    <ol>
                      <li>
                        <p>This Regulation is not a public offer.</p>
                      </li>
                      <li>
                        <p>These Regulations contain a description of the functionality of the Platform and the procedure for performing actions related to the receipt of services between the User and the Operator.</p>
                      </li>
                      <li>
                        <p>The fact of registration of the User on the Site is the full acceptance of the terms of this Regulation and all its annexes in editions, as well as further changes (additions) made in accordance with the terms of this Regulation in the form in which they are set forth.</p>
                      </li>
                      <li>
                        <p>By accepting the rules of the Regulation:</p>
                        <ul>
                          <li><p>The user confirms that the Regulations are clear to him and do not contain conditions that are clearly burdensome for him;</p></li>
                          <li><p>The User agrees to the processing, storage of his personal data to the Contractor, in accordance with the documents that are on the Site in the public domain (“Privacy Policy").</p></li>
                        </ul>
                      </li>
                      <li><p>If the User does not agree with any provision of the Regulations and (or) annexes to it, he is obliged to immediately stop using the Platform.</p></li>
                      <li><p>Informing Users about changes (additions) to the Regulations is carried out by the Operator by mandatory placement of a new edition on the Platform.</p></li>
                      <li><p>The publication of a new version of the Regulations takes place at least 3 (three) days prior to its entry into force, unless such changes (additions) are related to changes in the legislation of the Republic of Latvia, for which a different period is set.</p></li>
                      <li><p>Any action performed on the Site by the User using the data of his Account is considered an action performed by the User or a person authorized by him. User is responsible for violation of the rules of this Regulation, and actions contrary to the requirements of the legislation of the Republic of Latvia.</p></li>
                    </ol>
                  </li>
                  <li>
                    <h2>Rights and obligations of the parties</h2>
                    <ol>
                      <li>
                        <h3>The Operator undertakes</h3>
                        <ol>
                          <li><p> Ensure the performance of the Platform in accordance with the Regulations.</p></li>
                          <li><p>Provide technical support to Users on the operation of the Site. Technical support is provided during business hours using the contacts listed on the Platform.</p></li>
                          <li><p>Post a warning on the Site, or inform the Users in any other way, about the planned technical work at least 1 (one) day in advance.</p></li>
                          <li><p>Improve the functionality of the Site.</p></li>
                          <li><p>Fix reported bugs.</p></li>
                          <li><p>Maintain the performance of the Platform and all its functions 24/7.</p></li>
                        </ol>
                      </li>
                      <li>
                        <h3>The Operator has the right</h3>
                        <ol>
                          <li><p>Make changes (additions) to the Regulations, including annexes thereto, unilaterally.</p></li>
                          <li><p>Carry out routine maintenance work and make changes to the functionality of the Site. The date and time of routine maintenance is determined by the Operator independently, without agreement with the Users.</p></li>
                          <li><p>Deny the User any service provided on the Platform without reporting the reasons for the refusal.</p></li>
                          <li><p>Block the User without warning and at any time, in case of violation of the rules of the Regulation or in case of actions that are contrary to the laws of the Republic of Latvia.</p></li>
                          <li><p>Update the Platform code without notifying the User, in connection with which new functionality may be added, the current functionality removed or modified.</p></li>
                          <li><p>The Operator reserves the right to suspend the work of the Site for maintenance work, if possible (but not exclusively) at night or on weekends.</p></li>
                        </ol>
                      </li>
                      <li>
                        <h3>The User undertakes</h3>
                        <ol>
                          <li><p>Read Regulations and other published documents of the Platform.</p></li>
                          <li><p>Do not take any actions that may disrupt the operation of the Platform.</p></li>
                          <li><p>Provide correct data requested by the Operator.</p></li>
                          <li><p>Do not use the intellectual property of the Platform without the written consent of the Operator.</p></li>
                        </ol>
                      </li>
                      <li>
                        <h3>The User has the right</h3>
                        <ol>
                          <li><p>Contact the Operator during working hours for consulting assistance regarding the technical features of the Platform.</p></li>
                        </ol>
                      </li>
                    </ol>
                  </li>
                  <li>
                    <h2>Responsibility of the parties</h2>
                    <ol>
                      <li>
                        <h3>Operator's responsibility</h3>
                        <ol>
                          <li><p>The Operator is not liable for any damage, losses and other losses incurred by the User due to non-compliance with the requirements of the Regulations, as well as in case of violation of the law of the Republic of Latvia.</p></li>
                          <li>
                            <p>The Operator is not responsible for direct and indirect losses of the User, including lost profits and possible damage resulting from:</p>
                            <ul>
                              <li><p>illegal actions of Internet users aimed at violating information security or the normal functioning of the Platform;</p></li>
                              <li><p>lack of Internet connection between the User and the Operator's server;</p></li>
                              <li><p>conducting investigative measures by state and municipal bodies;</p></li>
                              <li><p>he formation of state regulation (or regulation by other organizations) of the economic activities of commercial organizations on the Internet and / or the establishment by the said entities of one-time restrictions that make it difficult or impossible to fulfill obligations under these Regulations;</p></li>
                              <li><p>actions of the Operator provided for in Section 3.2. of the current Regulation;</p></li>
                              <li><p>due to force majeure circumstances.</p></li>
                            </ul>
                          </li>
                          <li><p>The Operator is not responsible for the User's actions performed using the Platform, and not responsible for the User's failure to fulfill his obligations to third parties, including other users of the Platform;</p></li>
                          <li><p>The Operator is not responsible for non-delivery by the mobile provider of the SMS sent by the Operator or for non-delivery of e-mail.</p></li>
                        </ol>
                      </li>

                      <li>
                        <h3>User’s responsibility</h3>
                        <ol>
                          <li><p>For the safety of their data for access to the Platform and for losses that may arise due to unauthorized access;</p></li>
                          <li><p> For the reliability and relevance of the information and documents provided to the Operator;</p></li>
                          <li><p>If someone writes to the User on behalf of the Operator in order to provide personal data or any other data, the User must inform the Operator about this, using the contacts indicated on the Resource https://damask-trading.com/;</p></li>
                          <li><p>For losses that may arise due to force majeure circumstances.</p></li>
                        </ol>
                      </li>
                    </ol>
                  </li>
                  <li>
                    <h2>How the Platform works</h2>
                    <ol>
                      <li><p>If the current version of the Regulations does not describe certain functions of the Platform, this means that the Site in this part operates in test mode and the Operator does not guarantee the correctness and stability of such functions.</p></li>
                      <li><p>In case of any questions base on section 3.4.1, the User can use the feedback form in the “Contacts” section, write a message in the Telegram channel indicated on the site or write a letter to info@damask-trading.com.</p></li>
                      <li><p>In order to gain access to closed resources and additional functionality of the Platform, the User needs to register.</p></li>
                      <li><p>In order to register, the User must provide an email address, username and password. It is also necessary to accept the rules of this Regulation.</p></li>
                      <li><p>If the User already has an Account, then to log in, you must specify your e-mail address and the password that the User specified at the time of Registration.</p></li>
                      <li><p>If the user wants to recover the password, then it is necessary to use the "Password recovery" form. To do this, you must enter the User's e-mail address, which was indicated at the time of Registration. After that, an email with further instructions will be sent to the specified email address.</p></li>
                      <li>
                        <p>In order to use the services provided by the Platform, the User must:</p>
                        <ol>
                          <li><p>Have an account on one of the trading exchanges with which the Platform is integrated;</p></li>
                          <li><p>Generate an API key on the trading exchange;</p></li>
                          <li><p>Bind the generated API key in the personal account of the Platform;</p></li>
                          <li><p>Set up a trading robot in the personal account of the Platform.</p></li>
                        </ol>
                      </li>
                    </ol>
                  </li>
                  <li>
                    <h2>How the trading robot works</h2>
                    <ol>
                      <li><p>After the User has set up the Trading Robot, its work begins. The Robot does not have access to the User's money. The trading robot can only buy and sell cryptocurrencies. This restriction is configured on the trading exchange with which the site is integrated. The trading robot is based on a special algorithm that analyzes the cryptocurrency market to find entry points for a trade. Certain cryptocurrencies are selected according to special criteria, after which the robot makes a purchase. After opening a transaction, the system monitors the price of the acquired asset in real time. Depending on how the price behaves in the market, the system performs certain actions. The ultimate goal of the robot is to close the deal and make a profit, in other words, to sell the purchased asset for more than it was purchased.</p></li>
                    </ol>
                  </li>
                  <li>
                    <h2>Confidentiality</h2>
                    <ol>
                      <li><p>Confidential information does not include public information.</p></li>
                      <li>
                        <p>Not a single employee of the Operator has the right to demand from the User:</p>
                        <ol>
                          <li><p>account data for access to the Platform;</p></li>
                          <li><p>any other personal data, except for those that the user himself leaves on the Platform during registration;</p></li>
                          <li><p>account data for access to the Trading Exchange.</p></li>
                        </ol>
                      </li>
                    </ol>
                  </li>
                </ol>
              </div>
            </div>
          </div><!-- end row -->
        </div>
      </section>
      <Footer></Footer>
    </div>
</template>
